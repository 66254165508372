import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Block, BlockBody, BlockBodyContent, BlockHeader } from "styles";

export const WeightReport = ({ gpd, averageWeights }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    const data = {
      labels: [
        "Semana 0",
        "Semana 1",
        "Semana 2",
        "Semana 3",
        "Semana 4",
        "Semana 5",
        "Semana 6",
      ],
      datasets: [
        {
          label: "Cobb Real",
          data: Object.values(averageWeights.Cobb)
            .slice(0, 7)
            .map((value) => +value),
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          tension: 0.4,
        },
        {
          label: "Cobb Estimado",
          data: gpd.Cobb.filter(({ week }) => week < 7).map(
            (data) => data.weight
          ),
          borderColor: "rgba(255, 99, 132, 1)",
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          tension: 0.4,
        },
        {
          label: "Ross Real",
          data: Object.values(averageWeights.Ross)
            .slice(0, 7)
            .map((value) => +value),
          borderColor: "rgba(54, 162, 235, 1)",
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          tension: 0.4,
        },
        {
          label: "Ross Estimado",
          data: gpd.Ross.filter(({ week }) => week < 7).map(
            (data) => data.weight
          ),
          borderColor: "rgba(255, 159, 64, 1)",
          backgroundColor: "rgba(255, 159, 64, 0.2)",
          tension: 0.4,
        },
        {
          label: "Hubbard Real",
          data: Object.values(averageWeights.Hubbard)
            .slice(0, 7)
            .map((value) => +value),
          borderColor: "rgba(153, 102, 255, 1)",
          backgroundColor: "rgba(153, 102, 255, 0.2)",
          tension: 0.4,
        },

        {
          label: "Hubbard Estimado",
          data: gpd.Rubbard.filter(({ week }) => week < 7).map(
            (data) => data.weight
          ),
          borderColor: "rgba(255, 206, 86, 1)",
          backgroundColor: "rgba(255, 206, 86, 0.2)",
          tension: 0.4,
        },
      ],
    };
    setData(data);
  }, [gpd, averageWeights]);

  return (
    <Block id={`weight`}>
      <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/menu-top-connect.png">
        Peso Estimado x Real
      </BlockHeader>
      <BlockBody>
        <BlockBodyContent>
          <Line
            data={data}
            options={{
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      max: 4,
                    },
                  },
                ],
              },
            }}
          />
        </BlockBodyContent>
      </BlockBody>
    </Block>
  );
};
